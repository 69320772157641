<template>
    <RightSideBar @close="$emit('close','download')" button-class="bg-dynamicBackBtn text-white" :submit="'Yes, Download'" :cancel="'No, Cancel'" :loading="isLoading" @submit="submit">
      <template v-slot:title>
        <div class="text-darkPurple flex gap-5 items-center cursor-pointer">
          <div class=""  @click="closeSidebar()"><icon icon-name="back_arrow" size="s" class="w-8 h-8 rounded text-romanSilver"/></div> Download Folder(s)
        </div>
      </template>
      <div class="flex flex-col justify-center items-center gap-2 mt-20">
            <img :src="require('@/assets/images/download-box.png')" alt="not_found">
            <div class="flex justify-center items-center text-center text-jet font-semibold">
                Confirm you want to download this folder
            </div>
            <p class="flex justify-center items-center text-center px-10 text-sm">
                Your folders will be compressed and zipped for download.
            </p>
      </div>
    </RightSideBar>
</template>

<script>
  import RightSideBar from "../RightSideBar";

  export default {
    name: "DownloadFolder",
    components: {
      RightSideBar
    },
    props: {
        item: {
            type: Object,
            default: () => ({})
        }
    },
    data() {
      return {
        isLoading: false,
      };
    },
    methods: {
        closeSidebar() {
            this.$emit('close','download');
        },
        async submit() {
            if(this.item.numberOfFiles ===  0){
              return this.$toasted.error(`Can't download an empty folder.`,{duration: 6000});
            }
            this.isLoading = true;
            try {
              const data = await this. $_downloadFolderAsZip(this.item.folderId)
              const blob = new Blob([data.data], { type: 'application/zip' });
              const a = document.createElement('a');
              const url = window.URL.createObjectURL(blob)
              a.href = url;
              a.download = this.item.name;
              a.style.display= 'none';
              document.body.appendChild(a);
              a.click()

              window.URL.revokeObjectURL(url);
              document.body.removeChild(a);
              this.closeSidebar()
              this.isLoading = false;

              return this.$toasted.success(`Folder downloaded successfully.`,{duration: 6000});
            }catch(error) {
              this.isLoading = false;
              return this.$toasted.error(`Error downloading folder.`,{duration: 6000});
            }
        }
    }
  };
</script>

<style scoped>
</style>
